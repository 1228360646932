import { AuthError, InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { FC, useEffect } from "react";
import LoadSplash from "./LoadSplash";
import { Button } from "@chakra-ui/react";

const isRedirectError = (error: AuthError | null) => {
  if (
    !!error &&
    (error instanceof InteractionRequiredAuthError ||
      // Handle issues when refreshing expired tokens from different resources
      // ex: after an environment switch
      error.errorMessage.match(/^90009/))
  ) {
    return true;
  }

  return false;
};

/**
 * Verifies that a user is logged in. If not it will invoke silent login
 * and then, if unsuccessful, redirect login.
 * Using since <MsalAuthenticationTemplate> doesn't support silent logins...
 * @returns
 */
export const RequireLogin: FC = ({ children }) => {
  const { login, error } = useMsalAuthentication(InteractionType.Silent);
  const msal = useMsal();

  useEffect(() => {
    if (isRedirectError(error)) {
      login(InteractionType.Redirect);
    }
  }, [error, login]);

  if (error) {
    if (isRedirectError(error)) {
      return <LoadSplash message="Signing in..." />;
    }

    return (
      <LoadSplash message={"Signin Error: " + error}>
        <Button
          onClick={async () => {
            // In the instance there is a non-interactionRequiredError
            // the user may end up in a situation where they can't login.
            // It's easiest to just clear the cache and have the user login
            // with fresh start.
            await msal.instance.clearCache();
            login(InteractionType.Redirect);
          }}
          bgColor="hermes.400"
          color="black"
        >
          Retry
        </Button>
      </LoadSplash>
    );
  }

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoadSplash message="Signing in..." />
      </UnauthenticatedTemplate>
    </>
  );
};
