import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { CustomBox } from "@components/CustomBox";
import Persona from "@components/Persona";
import { KeyboardEventHandler, useMemo, useState } from "react";
import { defaultRequest, getTenantAuthority } from "./auth";
import groupBy from "lodash/groupBy";
import { TenantDisplayName } from "@components/graph-picker/TenantDisplayName";
import { TenantLogo } from "@components/graph-picker/TenantLogo";
import { AiFillCheckCircle } from "react-icons/ai";
import { TenantLookup } from "@components/graph-picker/TenantLookup";
import { PromptState } from "msal/lib-commonjs/utils/Constants";
import { useQuery } from "react-query";
import { BsThreeDotsVertical } from "react-icons/bs";
import { VscAdd, VscAzure, VscPersonAdd, VscWarning } from "react-icons/vsc";

const useMsalAccounts = () => {
  const msal = useMsal();
  const groupedAccounts = useMemo(() => {
    const groupedAccounts = groupBy(msal.accounts, (a) => a.tenantId);
    return Object.entries(groupedAccounts).map(([_, a]) => a);
  }, [msal.accounts]);
  return groupedAccounts;
};

export interface AccountPickerProps {
  activeAccount?: AccountInfo | null;
  onChange: (account: AccountInfo) => unknown;
  onTenantLogin?: (account: AccountInfo) => unknown;
}

export const AccountPicker = ({
  activeAccount,
  onChange,
  onTenantLogin = (a) => {},
}: AccountPickerProps) => {
  const msal = useMsal();
  const groupedAccounts = useMsalAccounts();

  const tenantSearchModal = useDisclosure();
  const [tenantAuthority, setTenantAuthority] = useState("");

  const loginToTenant = (tenantId: string) => {
    return msal.instance.loginPopup({
      ...defaultRequest,
      authority: getTenantAuthority(tenantId),
      prompt: PromptState.SELECT_ACCOUNT,
    });
  };

  const isMsalInProgress = msal.inProgress !== InteractionStatus.None;

  return (
    <Flex direction="column" gap={4}>
      {groupedAccounts.map((g) => (
        <CustomBox variant="card" pt={2} p={1} key={g[0].tenantId}>
          <Flex align="center" gap={2} px={2} py={1} minH={10}>
            <TenantLogo tenantId={g[0].tenantId} w={8} />
            <Box flex="1">
              <TenantDisplayName
                tenantId={g[0].tenantId}
                fontSize="sm"
                fontWeight="semibold"
                isTruncated
              />
            </Box>
            <IconButton
              size="sm"
              variant="ghost"
              aria-label="Add account from tenant"
              title={
                isMsalInProgress
                  ? "Login in progress in popup window"
                  : "Add account from tenant"
              }
              icon={<VscAdd />}
              disabled={isMsalInProgress}
              onClick={() => {
                if (!isMsalInProgress) {
                  loginToTenant(g[0].tenantId);
                }
              }}
            />
          </Flex>
          <CustomBox pt={2} p={1}>
            {g.map((a) => (
              <AccountOption
                key={a.localAccountId}
                account={a}
                onClick={onChange}
                isActive={activeAccount?.localAccountId === a.localAccountId}
              />
            ))}
          </CustomBox>
        </CustomBox>
      ))}

      <ButtonGroup w="full" variant="ghost" size="sm">
        <Button
          flex="1"
          color="neutral.50"
          leftIcon={<VscPersonAdd />}
          disabled={isMsalInProgress}
          onClick={() => {
            if (!isMsalInProgress) {
              msal.instance.loginPopup({
                ...defaultRequest,
                prompt: PromptState.SELECT_ACCOUNT,
              });
            }
          }}
        >
          Add Account
        </Button>
        <Button
          flex="1"
          color="neutral.50"
          leftIcon={<VscAzure />}
          onClick={() => tenantSearchModal.onOpen()}
          title="Log in to an account from a specific tenant. This is useful for signing in to guest accounts."
        >
          Log In to Tenant
        </Button>
      </ButtonGroup>

      <Modal
        isOpen={tenantSearchModal.isOpen}
        onClose={tenantSearchModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Entra Tenant Login</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={4} color="neutral.50">
              <Text fontSize="sm">
                Enter an Entra tenant ID or domain name to log in to that
                tenant.
              </Text>
              <Box fontSize="xs" color="neutral.100" px={2}>
                <Text>ex:</Text>
                <Text px={2}>c3637a33-14e9-4b54-a28e-f7cac55a8d1b</Text>
                <Text px={2}>xboxstudios.com</Text>
              </Box>
              <TenantLookup
                onChange={(a) => {
                  setTenantAuthority(a);
                }}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              disabled={!tenantAuthority || isMsalInProgress}
              isLoading={
                isMsalInProgress &&
                msal.inProgress !== InteractionStatus.AcquireToken
              }
              title={
                isMsalInProgress
                  ? "Login in progress in popup window"
                  : "Log in to tenant"
              }
              onClick={() => {
                if (!isMsalInProgress) {
                  loginToTenant(tenantAuthority).then((r) => {
                    onTenantLogin(r.account as AccountInfo);
                    tenantSearchModal.onClose();
                  });
                }
              }}
            >
              Log in
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

interface AccountOptionProps {
  account: AccountInfo;
  onClick: (a: AccountInfo) => unknown;
  isActive: boolean;
}

const AccountOption = ({ account, onClick, isActive }: AccountOptionProps) => {
  const msal = useMsal();

  // React-query doesn't persist errors between fetches. Store the
  // error flag here so that we can display an error icon on the account
  // even if a refetch is triggered.
  const [isErr, setIsErr] = useState(false);

  // Check for token errors. In most cases this
  // will be due to the account's refresh token being expired
  // Note: even though we're using the isErr state flag to handle error logic,
  //       we need to use at least one of the useQuery variables in the component
  //       for the query to run.
  const { isError } = useQuery({
    queryKey: [
      "tenant",
      account.tenantId,
      account.localAccountId,
      "checkAccountExpiration",
    ],
    queryFn: async () => {
      const response = await msal.instance.acquireTokenSilent({
        ...defaultRequest,
        account: account,
      });
      return response;
    },
    onError: (e) => {
      if (!isErr && !!e) {
        setIsErr(true);
      } else if (isErr && !e) {
        setIsErr(false);
      }
    },
    refetchOnWindowFocus: true,
    staleTime: 1000000 * 60 * 5,
  });

  const selectedDeviceBg = useColorModeValue("blue.100", "blue.800");
  const errorBg = useColorModeValue("red.100", "rgba(255, 0, 0, 0.1)");

  const isMsalInProgress = msal.inProgress !== InteractionStatus.None;

  return (
    <CustomBox
      key={account.localAccountId}
      variant="selectable"
      px={2}
      py={1}
      tabIndex={0}
      onKeyDown={
        ((e) => {
          if (e.key === "Enter") {
            onClick(account);
          }
        }) as KeyboardEventHandler<HTMLDivElement>
      }
      onClick={() => onClick(account)}
      backgroundColor={isActive ? selectedDeviceBg : isErr ? errorBg : "none"}
      w="full"
      display="flex"
      alignItems="center"
      gap={2}
    >
      <Box minW={2}>
        {isActive ? (
          <Icon as={AiFillCheckCircle} color="green.500" w="3" />
        ) : isError || isErr ? (
          <Icon as={VscWarning} color="red.500" w="3" />
        ) : (
          <></>
        )}
      </Box>
      <Box>
        <Persona
          userId={account.localAccountId}
          tenantId={account.tenantId}
          text={account.name}
          email={account.username}
          size="sm"
        />
      </Box>
      <Box flex="1" overflow="hidden">
        <Text
          fontSize="xs"
          fontWeight="semibold"
          isTruncated
          title={account.name}
        >
          {account.name}
        </Text>
        <Text
          fontSize="xs"
          color="neutral.100"
          isTruncated
          title={account.username}
        >
          {account.username || account.localAccountId}
        </Text>
      </Box>
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          size="xs"
          variant="ghost"
          icon={<BsThreeDotsVertical />}
          onClick={(e) => e.stopPropagation()}
        />
        <MenuList>
          {isErr && (
            <MenuItem
              isDisabled={isMsalInProgress}
              onClick={() => {
                if (!isMsalInProgress) {
                  msal.instance.loginRedirect({
                    ...defaultRequest,
                    account,
                    loginHint: account.username,
                  });
                }
              }}
            >
              Fix account
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              msal.instance.logout({
                account,
                logoutHint: account.username,
                postLogoutRedirectUri: window.location.href,
                // perform a "local" logout only
                // otherwise the user's session ends on the server
                // and logs them out of all other apps
                // see: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#skipping-the-server-sign-out
                onRedirectNavigate: (url) => false,
              });
            }}
          >
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
    </CustomBox>
  );
};
