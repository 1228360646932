import { FC, useEffect } from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  getAppInsights,
  initAppInsights,
  reactPlugin,
} from "@logs/appInsights";
import { useHistory } from "react-router";
import { registerSWMessageTokenHandler } from "service-workers/serviceWorkerAuthTokenHandler";

const LoggingContextProvider: FC<{}> = ({ children }) => {
  //initialize app insights with history object from react-router
  const history = useHistory();
  useEffect(() => {
    initAppInsights(history);

    // register the auth token handler for our service worker
    registerSWMessageTokenHandler(getAppInsights()).catch((e) => {});
    // Empty array calls useEffect callback only once
    // https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export default LoggingContextProvider;
