/**
 * See https://chakra-ui.com/docs/theming/customize-theme for theme customizations.
 *
 * If the extended theme gets large it would be a good idea to break up the
 * theme into separate files and folders similar to this:
 * https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project
 */

import { extendTheme } from "@chakra-ui/react";
import Components from "./components";
import colors from "./foundations/colors";
import styles from "./styles";
import sizes from "./foundations/sizes";

const theme = extendTheme({
  styles,
  colors,
  sizes,
  components: { ...Components },
  config: {
    initialColorMode: "system",
    useSystemColorMode: false,
  },
});

export default theme;
