import { FC } from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { versionKeys } from "./hermes-queries/query-keys";
import { useCustomToast } from "@components/toast/useCustomToast";

// TODO: persist state between tabs/windows?
// Or wait for server push/streaming?
// broadcastQueryClient({
//     queryClient,
//     broadcastChannel: "hermes-web-ui",
// })

/**
 * Provides a global cache for HTTP requests made with one of the query hooks.
 * https://react-query.tanstack.com/
 */
export const HttpCacheContext: FC = ({ children }) => {
  const toast = useCustomToast();

  let queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000,
        // This causes a lot of refetches whenever the window is refocused
        // It's more efficient to set this value on the query when needed
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        // 🎉 only show error toasts if we already have data in the cache
        // which indicates a failed background update
        if (query.state.data !== undefined) {
          toast({
            status: "error",
            title: "Error",
            // @ts-ignore
            description: typeof error === "string" ? error : error?.message,
          });
        }
      },
    }),
  });

  // Version info hardly ever changes so it's reasonable
  // to cache version data locally to avoid unecessary
  // repeat calls.
  queryClient.setQueryDefaults(versionKeys.all, {
    staleTime: 1000 * 60 * 60,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* 
                Note: Devtools are automatically not included in production builds
                https://react-query.tanstack.com/devtools
            */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
